<header class="header">
  <div class="header__container">
    <nav class="header__nav">
      <a routerLink="/" class="header__home-link">
        <mat-icon class="header__home-icon" svgIcon="home" />
      </a>
      <ul class="header__nav-list">
        @for (button of menuItems; track $index) {
        <li class="header__nav-item">
          <a class="header__nav-link" (click)="navButtonAction(button)">
            {{ button.name }}
          </a>
        </li>
        }
      </ul>
      <ul class="header__nav-mobile-list">
        <li class="header__nav-item">
          <a class="header__nav-link" (click)="navButtonAction(menuItems[0])"
            >Стать&nbsp;партнером
          </a>
        </li>
        <li class="header__nav-item">
          <a class="header__nav-link" (click)="toggleMenu()"> Все&nbsp;меню </a>
        </li>
      </ul>
    </nav>
    <!-- <button
      class="header__buy-button"
      id="pirexpo-ticket-widget"
      data-pirexpo-widget-shop-id="1"
      type="button"
    >
      <span>купить</span>
      <span>билет</span>
    </button> -->
  </div>
</header>
